<template>
  <div class="industry">
    <div class="industry-second">
      <div class="industry-second-up">
        <a-form
          class="ant-advanced-search-form"
          :form="form"
          @submit="handleSearch"
        >
          <a-row class="ant-advanced-search-form-top" :gutter="24">
            <a-col :span="8">
              <!--<a-form-item label="行政区划：">-->
              <!--<a-select-->
              <!--mode="multiple"-->
              <!--style="width: 100%"-->
              <!--placeholder="请选择"-->
              <!--@change="handleChangeArea"-->
              <!--v-model="param.selectedAreaId"-->
              <!--&gt;-->
              <!--<a-select-option-->
              <!--:value="item.id"-->
              <!--v-for="(item, index) in xzqh"-->
              <!--:key="index"-->
              <!--&gt;-->
              <!--{{ item.name }}-->
              <!--</a-select-option>-->
              <!--</a-select>-->
              <!--</a-form-item>-->
              <a-form-item label="行政区划：">
                <a-cascader
                  :options="options"
                  @change="areaChange"
                  placeholder="请选择"
                  expandTrigger="hover"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item label="水源类型：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.waterType"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in waterTypes"
                    :key="index"
                    @change="handleChangeWaterType"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="工程名称：">
                <a-input placeholder="请输入工程名称" v-model="param.name" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row class="ant-advanced-search-form-top" :gutter="24">
            <a-col :span="8">
              <a-form-item label="供水规模：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChange"
                  v-model="param.wasuScale"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in wasuScales"
                    :key="index"
                    @change="handleChangeWasuScale"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="工程运行管理单位：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChange"
                  v-model="param.natManageUnit"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in natManageUnits"
                    :key="index"
                    @change="handleChangeNatManageUnit"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="年份：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.reportYear"
                  @change="handleChange"
                >
                  <a-select-option v-for="i in year" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" :style="{ textAlign: 'right' }">
              <a-button type="primary" html-type="submit">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                重置
              </a-button>
            </a-col>
          </a-row>
          <!--<div class="ant-advanced-search-form-bottom-all">
            <a-row class="ant-advanced-search-form-bottom">
              <a-col :span="24">
                <div class="industry-second-up-select">
                  <div class="industry-second-up-select-l">
                    供水规模：
                  </div>
                  <div class="industry-second-up-select-r">
                    <div
                      v-for="(item, index) in tabListd"
                      :key="index"
                      :class="
                        wasuScales.indexOf(item) !== -1 ? 'select-rd' : ''
                      "
                      @click="checkProject(item)"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24" :style="{ textAlign: 'right' }">
                <a-button type="primary" html-type="submit">
                  查询
                </a-button>
                <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                  重置
                </a-button>
              </a-col>
            </a-row>
          </div>-->
        </a-form>
      </div>
      <div class="industry-second-down">
        <div class="industry-second-down-con">
          <div class="detail-top-left flex align-center">
            <div class="detail-top-left-shu"></div>
            <div class="detail-top-left-text">集中供水工程详表</div>
          </div>
          <div class="industry-second-down-top flex align-center">
            <div
              @click="back()"
              class="detail-back flex justify-center align-center"
              style="margin-right: 20px"
            >
              <img
                src="../../../assets/img/layout/back.png"
                class="detail-back-img"
              />
              <div class="detail-back-text">
                返回
              </div>
            </div>
            <!--<a-button-->
            <!--type="primary"-->
            <!--class="detail-top-right-left"-->
            <!--v-if="identity == 0 || identity == 1"-->
            <!--@click="selectAll(1)"-->
            <!--&gt;-->
            <!--查看全省所有的县-->
            <!--</a-button>-->
            <!--<a-button-->
            <!--type="primary"-->
            <!--class="detail-top-right-left"-->
            <!--v-if="identity == 2"-->
            <!--@click="selectAll(0)"-->
            <!--&gt;-->
            <!--查看全市所有的县-->
            <!--</a-button>-->
            <a-button type="primary">
              导出
            </a-button>
          </div>
        </div>
        <!-- 表格 -->

        <div class="detail-table">
          <div class="detail-table-con">
            <div class="detail-table-content">
              <div class="detail-table-content-top flex align-center ">
                <!-- <div
                  class="detail-table-content-itemA width80 flex align-center justify-center"
                >
                  <div class="detail-table-content-itemA-con">上报年度</div>
                </div>
                <div
                  class="detail-table-content-itemA width200 flex align-center justify-center"
                >
                  <div class="detail-table-content-itemA-con">
                    供水工程 名称
                  </div>
                </div> -->
                <div
                  class="detail-table-content-items"
                  v-for="(item, index) in columns"
                  :key="index"
                >
                  <div
                    v-if="!item.children"
                    class="detail-table-content-itemA width80 flex align-center justify-center relative"
                    :style="`width: ${item.width}px`"
                  >
                    <div class="detail-table-content-itemA-con">
                      {{ item.title }}
                    </div>
                    <!-- <img
                      @click="activefn(index, false)"
                      src="../../../assets/img/layout/active.png"
                      class="table-activeB absolute"
                      v-if="item.active"
                    />
                    <img
                      @click="activefn(index, true)"
                      src="../../../assets/img/layout/noActive.png"
                      class="table-activeB absolute"
                      v-else
                    /> -->
                  </div>
                  <div v-else class="detail-table-content-itemB relative">
                    <div class="detail-table-content-itemB-top">
                      {{ item.title }}
                    </div>
                    <!-- <img
                      @click="activefn(index, false)"
                      src="../../../assets/img/layout/active.png"
                      class="table-activeB absolute"
                      v-if="item.active"
                    />
                    <img
                      @click="activefn(index, true)"
                      src="../../../assets/img/layout/noActive.png"
                      class="table-activeB absolute"
                      v-else
                    /> -->
                    <div
                      class="detail-table-content-itemB-bottom flex align-center"
                    >
                      <div
                        v-for="(i, indexi) in item.children"
                        :key="indexi"
                        class="detail-table-content-itemB-bottom-item flex align-center justify-center"
                        :style="`width: ${i.width}px`"
                      >
                        <div
                          v-html="i.title"
                          class="detail-table-content-itemB-bottom-item-text"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--                <div-->
                <!--                  class="detail-table-content-itemA width120 flex align-center justify-center"-->
                <!--                >-->
                <!--                  <div class="detail-table-content-itemA-con">-->
                <!--                    供水单位服务热线-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div
                class="detail-table-content-list flex align-center"
                v-for="(item, index) in list"
                :key="index"
              >
                <div
                  class="detail-table-content-list-item "
                  :style="`width: ${item2.width}px`"
                  v-for="(item2, index2) in item"
                  :key="index2"
                >
                  {{ item2.value }}
                </div>
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.cwsName }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.cwsName }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.cwsName }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.reportYear }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width200">-->
                <!--                  {{ item.cwsName }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width300">-->
                <!--                  {{ item.cwsLoc }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.cwsLong }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.cwsLat }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.totalInvest }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.midInvest }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.yearWatter }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.compCheck }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.isMoreHundred }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.isMoreHundredCount }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.desWasuScal }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.yearWatter }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.desWasuPop }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.waterPer }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.waterPerH }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.teachStudNum }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.poorHome }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.poorPop }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.fluoOverPeoplenum }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.waterType }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.surWaterName }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.waterQualityState }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.buildProArea }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.getWaterCard }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.sanitationCard }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.waterSupRate }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.leakageRate }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.purWay }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.purCondition }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.disinWay }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.disinCondition }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.buildTestCabinet }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.operCondition }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.compuMoni }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.moniOperCondition }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.viewSys }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.viewSysCondition }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.allCost }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.billingWay }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.resLiveCost }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.waterRateRecovery }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.inOutCondition }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width150">-->
                <!--                  {{ item.proInstiName }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.proPerNum }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.higherInstiName }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.natManageUnit }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.waterRespName }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width80">-->
                <!--                  {{ item.waterServicePhone }}-->
                <!--                </div>-->
                <!--                <div class="detail-table-content-list-item width100">-->
                <!--                  {{ item.contactWay }}-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="detail-table-page">
          <template>
            <template>
              <a-pagination
                :current="current"
                :total="total"
                @change="onChange"
              />
            </template>
          </template>
        </div>
        <!-- 框架带的 -->
        <!-- <div class="detail-table">
          <a-table
            :columns="columns"
            :data-source="list"
            bordered
            size="middle"
            :scroll="{ x: 'calc(700px + 50%)', y: 240 }"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
const columns = [];

// 引人组件
import { getAreas, getDefaultArea } from "@/api/area/index";
import { baseMenuList } from "@/api/industry/index";
import { getBaseCwsePage } from "@/api/industry/focus";
import { getAreaTreeList } from "@/api/basic/common";

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "advanced_search" }),
      xzqh: ["江苏省", "北京市", "山西省"], //测试数据/行政区划
      year: [], //测试数据/年度
      waterTypes: [],
      natManageUnits: [],
      wasuScales: [], //切换工程运行管理单位index
      // 筛选条件
      param: {
        defaultAreaId: undefined,
        selectedAreaId: undefined,
        areaId: undefined,
        reportYear: undefined,
        waterType: undefined,
        natManageUnit: undefined,
        wasuScale: undefined,
        name: undefined
      },
      options: [
        {
          value: "1",
          label: "山西省"
        }
      ], //行政区划
      userAreaId: {
        id: 1
      },
      listParam: {
        areaIds: "1",
        name: "",
        natManageUnits: "",
        waterType: "0",
        wasuScales: "0",
        reportYear: "2020",
        updateMode: "0",
        pageSize: 5,
        pageNumber: 1
      },
      identity: 0,
      tabListd: [
        "全部",
        "县级水务局",
        "县级供水管理总站",
        "片区或流域等县级派生水管站（所）",
        "乡镇政府或水管站",
        "国有公司",
        "自来水公司",
        "股份制公司",
        "民营公司",
        "外资公司",
        "私人租赁或承包",
        "供水协会",
        "合作组织或用水户协会",
        "村委会",
        "其他"
      ], //工程运行管理单位
      columns,
      current: 1, //分页页码
      total: 0, //总数
      pageSize: 20,
      pageSizeOptions: ["20", "30", "40", "50"],
      list: []
    };
  },
  created() {
    var date = new Date();

    this.listParam.reportYear = date.getFullYear();
    this.year.unshift(date.getFullYear() - 1);
    for (let i = 0; i < 9; i++) {
      this.year = this.year.concat(date.getFullYear() + i);
    }
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.userAreaId.id = area.areaId;
    getAreaTreeList(this.userAreaId).then(response => {
      this.options = response.data.list;
    });
    this.getAreaList();
  },
  mounted() {},
  methods: {
    areaChange(value) {
      this.listParam.areaIds = value[value.length - 1];
      this.param.areaId = value[value.length - 1];
      console.log(value);
    },
    async getData() {
      await baseMenuList({
        menu: "水源类型"
      }).then(res => {
        // console.log('水源类型')
        // console.log(res)
        this.waterTypes = res.data.list;
      });
      await baseMenuList({
        menu: "工程运行管理单位性质"
      }).then(res => {
        // console.log('工程运行管理单位')
        // console.log(res)
        this.natManageUnits = res.data.list;
      });
      await baseMenuList({
        menu: "集中设施"
      }).then(res => {
        // console.log('工程运行管理单位')
        // console.log(res)
        this.wasuScales = res.data.list;
      });
      this.getBaseCwse();
    },
    getAreaList() {
      let data = getDefaultArea();
      console.log(data);
      this.param.defaultAreaId = data.id;
      this.identity = data.identity;
      this.param.areaName = data.name;
      this.param.reportYear = 2020;

      if (!this.param.areaId) {
        this.param.areaId = this.param.defaultAreaId;
      }

      getAreas({
        id: this.param.areaId
      }).then(res => {
        if (res.code == 200) {
          this.xzqh = res.data.list;
        }
        this.getData();
      });
    },
    //跳转
    router(x) {
      this.$router.push({
        path: x
      });
    },
    onChange(current) {
      this.current = current;
      this.getBaseCwse();

      // console.log(this.current);
    },
    // 切换工程运行管理单位
    checkProject(item) {
      if (this.wasuScales.indexOf(item) == -1) {
        this.wasuScales.push(item);
      } else {
        this.wasuScales.splice(
          this.wasuScales.findIndex(i => i === item),
          1
        );
      }
    },

    //查询
    handleSearch(e) {
      e.preventDefault();
      this.getBaseCwse();
    },
    //清除
    handleReset() {
      this.param.areaId = this.param.defaultAreaId;
      this.param.selectedAreaId = undefined;
      this.param.reportYear = 2020;
      this.param.waterType = undefined;
      this.param.natManageUnit = undefined;
      this.param.wasuScale = undefined;
      this.param.name = undefined;
      this.getBaseCwse();
    },
    //多选运行管理单位
    handleChange(value) {
      this.param.reportYear = value;
      console.log(`selected ${value}`);
    },
    handleChangeArea(value) {
      this.param.areaId = value;
      this.param.selectedAreaId = value;
    },
    handleChangeWaterType(value) {
      this.param.waterType = value;
    },
    handleChangeNatManageUnit(value) {
      this.param.natManageUnit = value;
    },
    handleChangeWasuScale(value) {
      this.param.wasuScale = value;
    },
    // 统计数据
    getBaseCwse() {
      let _this = this;
      _this.list = [];
      getBaseCwsePage({
        areaIds: _this.param.areaId,
        reportYear: _this.param.reportYear,
        waterTypes: _this.param.waterType,
        natManageUnits: _this.param.natManageUnit,
        wasuScales: _this.param.wasuScale,
        name: _this.param.name,
        pageNumber: _this.current,
        pageSize: 10
      }).then(res => {
        if (res.code == 200) {
          // 表头权限
          let data_permissions = JSON.parse(
            localStorage.getItem("data_permissions")
          );
          let admin = localStorage.getItem("admin");

          // 表头
          if (admin == "1") {
            _this.columns = [
              {
                title: "序号",
                dataIndex: "key",
                key: "key",
                width: 80
              },
              {
                title: "省名称",
                dataIndex: "province",
                key: "province",
                width: 80
              },
              {
                title: "市名称",
                dataIndex: "city",
                key: "city",
                width: 80
              },
              {
                title: "县名称",
                dataIndex: "country",
                key: "country",
                width: 80
              },
              {
                title: "上报年度",
                dataIndex: "reportYear",
                key: "reportYear",
                width: 80,
                active: false
              },
              {
                title: "供水工程名称",
                dataIndex: "cwsName",
                key: "cwsName",
                width: 200,
                active: false
              },
              {
                title: "所在位置",
                width: 310,
                active: false,
                children: [
                  {
                    title: "乡镇、村庄 (或农场、林 场、连队)名 称",
                    width: 300,
                    dataIndex: "cwsLoc",
                    key: "cwsLoc"
                  },
                  {
                    title: `东经(°´")`,
                    width: 80,
                    dataIndex: "cwsLong",
                    key: "cwsLong"
                  },
                  {
                    title: `北纬(°´")`,
                    width: 80,
                    dataIndex: "cwsLat",
                    key: "cwsLat"
                  }
                ]
              },
              {
                title: "建设情况",
                width: 320,
                active: false,
                children: [
                  {
                    title: "工程总投资 （万元）",
                    width: 80,
                    dataIndex: "totalInvest",
                    key: "totalInvest"
                  },
                  {
                    title: "中央投资 (万元)",
                    width: 80,
                    dataIndex: "midInvest",
                    key: "midInvest"
                  },
                  {
                    title: "开始供水时间 （年月）",
                    width: 80,
                    dataIndex: "yearWatter",
                    key: "yearWatter"
                  },
                  {
                    title: "竣工验收 （是／否）",
                    width: 80,
                    dataIndex: "compCheck",
                    key: "compCheck"
                  }
                ]
              },
              {
                title: "工程性质",
                dataIndex: "name",
                key: "name",
                width: 160,
                active: false,
                children: [
                  {
                    title: "非打包工程",
                    width: 80,
                    isMoreHundred: "cwsLat",
                    key: "isMoreHundred"
                  },
                  {
                    title: "打包工程 处数（处）",
                    width: 80,
                    dataIndex: "isMoreHundredCount",
                    key: "isMoreHundredCount"
                  }
                ]
              },
              {
                title: "设计供水 能力(m3/d)",
                dataIndex: "desWasuScal",
                key: "desWasuScal",
                width: 80,
                active: false
              },
              {
                title: "年实际供 水量(m3)",
                dataIndex: "yearWatter",
                key: "yearWatter",
                width: 80,
                active: false
              },
              {
                title: "供水情况",
                dataIndex: "name",
                key: "name",
                width: 560,
                active: false,
                children: [
                  {
                    title: "实际供水 人数（人）",
                    width: 80,
                    dataIndex: "yearWatter",
                    key: "yearWatter"
                  },
                  {
                    title: "设计供水 人数（人）",
                    width: 80,
                    // dataIndex: "waterPer",
                    // key: "waterPer"
                    dataIndex: "desWasuPop",
                    key: "desWasuPop"
                  },
                  {
                    title: "其中供水 到户人数 （人）",
                    width: 80,
                    dataIndex: "waterPerH",
                    key: "waterPerH"
                  },
                  {
                    title: "其中受益 学校师生 人数（人）",
                    width: 80,
                    dataIndex: "teachStudNum",
                    key: "teachStudNum"
                  },
                  {
                    title: "其中覆盖 建档立卡 贫困户数 （户）",
                    width: 80,
                    dataIndex: "poorHome",
                    key: "poorHome"
                  },
                  {
                    title: "其中覆盖 建档立卡 贫困人数 （人）",
                    width: 80,
                    dataIndex: "poorPop",
                    key: "poorPop"
                  },
                  {
                    title: "其中2018年以来 解决的饮水型 氟超标人数 （人）",
                    width: 80,
                    dataIndex: "fluoOverPeoplenum",
                    key: "fluoOverPeoplenum"
                  }
                ]
              },
              {
                title: "水源",
                dataIndex: "name",
                key: "name",
                width: 400,
                active: false,
                children: [
                  {
                    title: "水源类型",
                    width: 80,
                    dataIndex: "waterType",
                    key: "waterType"
                  },
                  {
                    title: "地表水源 名称",
                    width: 80,
                    dataIndex: "surWaterName",
                    key: "surWaterName"
                  },
                  {
                    title: "水源水质 状况",
                    width: 80,
                    dataIndex: "waterQualityState",
                    key: "waterQualityState"
                  },
                  {
                    title: "是否建立水源 保护区或划定 水源保护范围",
                    width: 80,
                    dataIndex: "buildProArea",
                    key: "buildProArea"
                  },
                  {
                    title: "是否有取水许可证",
                    width: 80,
                    dataIndex: "getWaterCard",
                    key: "getWaterCard"
                  }
                ]
              },
              {
                title: "是否有卫生许可证",
                dataIndex: "sanitationCard",
                key: "sanitationCard",
                width: 80,
                active: false
              },
              {
                title: "供水保证率（%）",
                dataIndex: "waterSupRate",
                key: "waterSupRate",
                width: 80,
                active: false
              },
              {
                title: "管网漏损率（%）",
                dataIndex: "leakageRate",
                key: "leakageRate",
                width: 80,
                active: false
              },
              {
                title: "净化方式",
                dataIndex: "name",
                key: "name",
                width: 150,
                active: false,
                children: [
                  {
                    title: "方式",
                    width: 80,
                    dataIndex: "purWay",
                    key: "purWay"
                  },
                  {
                    title: "运行状况",
                    width: 70,
                    dataIndex: "purCondition",
                    key: "purCondition"
                  }
                ]
              },
              {
                title: "消毒方式",
                dataIndex: "name",
                key: "name",
                width: 150,
                active: false,
                children: [
                  {
                    title: "方式",
                    width: 80,
                    dataIndex: "disinWay",
                    key: "disinWay"
                  },
                  {
                    title: "运行状况",
                    width: 70,
                    dataIndex: "disinCondition",
                    key: "disinCondition"
                  }
                ]
              },
              {
                title: "水质化验室",
                dataIndex: "name",
                key: "name",
                width: 150,
                active: false,
                children: [
                  {
                    title: "是否建立",
                    width: 80,
                    dataIndex: "buildTestCabinet",
                    key: "buildTestCabinet"
                  },
                  {
                    title: "运行状况",
                    width: 70,
                    dataIndex: "operCondition",
                    key: "operCondition"
                  }
                ]
              },
              {
                title: "计算机监控系统",
                dataIndex: "name",
                key: "name",
                width: 150,
                active: false,
                children: [
                  {
                    title: "有/无",
                    width: 80,
                    dataIndex: "compuMoni",
                    key: "compuMoni"
                  },
                  {
                    title: "运行状况",
                    width: 70,
                    dataIndex: "moniOperCondition",
                    key: "moniOperCondition"
                  }
                ]
              },
              {
                title: "视频安防系统",
                dataIndex: "name",
                key: "name",
                width: 150,
                active: false,
                children: [
                  {
                    title: "有/无",
                    width: 80,
                    dataIndex: "viewSys",
                    key: "viewSys"
                  },
                  {
                    title: "运行状况",
                    width: 70,
                    dataIndex: "viewSysCondition",
                    key: "viewSysCondition"
                  }
                ]
              },
              {
                title: "水费水价",
                dataIndex: "name",
                key: "name",
                width: 400,
                active: false,
                children: [
                  {
                    title: "全成本水价（元/m³）",
                    width: 80,
                    dataIndex: "allCost",
                    key: "allCost"
                  },
                  {
                    title: "计费方式",
                    width: 80,
                    dataIndex: "billingWay",
                    key: "billingWay"
                  },
                  {
                    title: "居民生活执行水价（元/m³）",
                    width: 80,
                    dataIndex: "resLiveCost",
                    key: "resLiveCost"
                  },
                  {
                    title: "水费回收率（%）",
                    width: 80,
                    dataIndex: "waterRateRecovery",
                    key: "waterRateRecovery"
                  },
                  {
                    title: "收支情况",
                    width: 80,
                    dataIndex: "inOutCondition",
                    key: "inOutCondition"
                  }
                ]
              },
              {
                title: "运行管理",
                dataIndex: "name",
                key: "name",
                width: 400,
                active: false,
                children: [
                  {
                    title: "工程运行管理单位名称",
                    width: 150,
                    dataIndex: "proInstiName",
                    key: "proInstiName"
                  },
                  {
                    title: "工程专职运行管理人员数量（人）",
                    width: 80,
                    dataIndex: "proPerNum",
                    key: "proPerNum"
                  },
                  {
                    title: "工程上级主管单位名称",
                    width: 80,
                    dataIndex: "higherInstiName",
                    key: "higherInstiName"
                  },
                  {
                    title: "工程运行管理单位性质",
                    width: 80,
                    dataIndex: "natManageUnit",
                    key: "natManageUnit"
                  }
                ]
              },
              {
                title: "供水单位负责人",
                dataIndex: "name",
                key: "name",
                width: 180,
                active: false,
                children: [
                  {
                    title: "姓名",
                    width: 80,
                    dataIndex: "waterRespName",
                    key: "waterRespName"
                  },
                  {
                    title: "联系电话",
                    width: 100,
                    dataIndex: "waterServicePhone",
                    key: "waterServicePhone"
                  }
                ]
              },
              {
                title: "供水单位服务热线",
                dataIndex: "contactWay",
                key: "contactWay",
                width: 100,
                active: false
              }
            ];
            for (let i in res.data.list) {
              let result = res.data.list[i];
            }
          } else {
            let focus = data_permissions[1];
            this.columns = [];
            if (focus.checked) {
              // 表头
              let columns = focus.children;
              for (let i in columns) {
                let result = columns[i];
                if (result.checked) {
                  let column = {
                    title: result.name,
                    dataIndex: result.column,
                    key: result.column,
                    width: result.width
                  };
                  if (result.children) {
                    let children = [];
                    for (let j in result.children) {
                      let child = result.children[j];
                      children.push({
                        title: child.name,
                        dataIndex: child.column,
                        key: child.column,
                        width: child.width
                      });
                    }
                    column.children = children;
                  }
                  this.columns.push(column);
                }
              }
            }
          }

          // 根据表头取数据
          for (let j in res.data.list) {
            console.log(j);
            let r = res.data.list[j];
            let data = [];
            for (let k in this.columns) {
              let cos = this.columns[k];
              if (cos.key == "key") {
                data.push({
                  value: parseInt(this.current - 1) * 10 + parseInt(j) + 1,
                  width: cos.width
                });
              } else {
                if (cos.children) {
                  for (let m in cos.children) {
                    let child = cos.children[m];
                    data.push({
                      column: child.key,
                      value: r[child.key],
                      width: child.width
                    });
                  }
                } else {
                  data.push({
                    column: cos.key,
                    value: r[cos.key],
                    width: cos.width
                  });
                }
              }
            }
            console.log(data);
            _this.list.push(data);
          }
          _this.total = res.data.total;
        }
      });
    },
    activefn(index, active) {
      this.columns[index].active = active;
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="less">
.industry-second {
  width: 100%;
  .industry-second-up {
    padding: 1px;
    box-sizing: border-box;
  }
  .industry-second-ud {
    display: flex;
    padding: 20px 0 20px 0;
    .industry-second-udone {
      position: relative;
      cursor: pointer;
      width: 105px;
      text-align: center;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
  .industry-second-up-select {
    display: flex;
    align-items: flex-start;
    .industry-second-up-select-l {
      width: 100px;
      height: 40px;
      line-height: 40px;
    }
    .industry-second-up-select-r {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 40px;
        margin-right: 10px;
        padding: 0 15px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .select-rd {
        color: #ffffff;
        background: #1890ff;
      }
    }
  }
}
.more {
  cursor: pointer;
}
.ant-advanced-search-form {
  background: #ffffff;
  width: 100%;
  padding: 1px;
}
.ant-advanced-search-form-top {
  background: #ffffff;
  width: 100%;
  // height: 86px;
  // padding: 20px 0px;
  margin: 20px 0px;
  margin-left: 0px !important;
  display: flex !important;
  align-items: center;
}
.ant-row .ant-form-item {
  margin: 0px !important;
}
.ant-advanced-search-form-bottom-all {
  background: #ffffff;
  width: 100%;
  padding: 30px 20px 36px;
  box-sizing: border-box;
  margin-top: 20px;
}
.ant-advanced-search-form-bottom {
  width: 100%;
}
.industry-second-down {
  width: 100%;
  padding: 1px 20px 20px;
  box-sizing: border-box;
  background: #ffffff;
  margin-top: 20px;
}
.industry-second-down-top {
  padding: 20px 0px;
  justify-content: flex-end;

  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.industry-second-down-con {
  display: flex;
  justify-content: space-between;
}
.detail-top-right-left {
  margin-right: 21px;
}
.detail-table {
  padding: 12px 0px 0px 0px;
  width: 100%;
  // height: 700px;
  overflow: auto;
}
.detail-table-content {
  width: max-content;
  border-top: 1px solid #e1e1ee;
  border-left: 1px solid #e1e1ee;
}
.detail-table-content-top {
}
.table-active {
  top: -32px;
  left: 30px;
  width: 20px;
  height: 20px;
}
.table-activeB {
  top: -32px;
  left: 47%;
  width: 20px;
  height: 20px;
}
.width80 {
  width: 80px;
}
.width100 {
  width: 100px;
}
.width120 {
  width: 120px;
}
.width150 {
  width: 150px;
}
.width200 {
  width: 200px;
}
.width300 {
  width: 300px;
}
.detail-table-content-itemA {
  height: 120px;
  background: #fafafa;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  padding: 0px 10px;
  box-sizing: border-box;
}
.detail-table-content-itemA-con {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 18px;
}
.detail-table-content-itemB {
  height: 120px;
  background: #fafafa;
  box-sizing: border-box;
}
.detail-table-content-itemB-top {
  height: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 50px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
}
.detail-table-content-itemB-bottom-item {
  width: 80px;
  height: 70px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
}
.detail-table-content-itemB-bottom-item-text {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 16px;
}
.detail-table-content-list:hover {
  background: rgb(230, 247, 255);
}
.detail-table-content-list-item {
  height: 40px;
  text-align: center;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  word-wrap: break-word;
  line-height: 20px;
}
.detail-table-page {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.detail-back-con {
  margin-bottom: 20px;
}
.detail-back {
  width: 80px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  // margin-bottom: 21px;
  cursor: pointer;
}
.detail-back-img {
  width: 16px;
  height: 17px;
  margin-right: 8px;
}
.detail-back-text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.detail-top {
  width: 100%;
}
.detail-top-left-shu {
  width: 4px;
  height: 16px;
  background: #1890ff;
  border-radius: 2px;
  margin-right: 8px;
}
.detail-top-left-text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #28282c;
}
.detail-top-right-left {
  margin-right: 21px;
}
</style>
