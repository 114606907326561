import http from "@/plugins/https";

export function getBaseCwseList(data) {
  return http({
    url: `/baseCwse/list`,
    params:data,
    method: "GET"
  });
}

export function getBaseCwsePage(data) {
  return http({
    url: `/baseCwse/page`,
    params:data,
    method: "GET"
  });
}